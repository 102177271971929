import { useEffect, useState } from 'react';

import { AllowDenyPolicyType } from '__generated__/types';
import { useChatModelChatPatientWritePolicy } from 'features/Chat/model';
import { useUserModelStore } from 'features/Users/model';

interface IUseClinicModelWriteToClinicVisibilityProps {
  defaultClinicPolicy?: AllowDenyPolicyType;
  patientWriteButtonVisibilityPolicy?: AllowDenyPolicyType;
  clinicId?: string;
  patientPortalUserId?: string;
  isListing?: boolean;
}

interface IUseClinicModelWriteToClinicVisibilityReturn {
  isWriteToClinicButtonVisible: boolean;
}

export const useClinicModelWriteToClinicButtonVisibility = ({
  defaultClinicPolicy,
  patientWriteButtonVisibilityPolicy,
  clinicId,
  patientPortalUserId,
  isListing,
}: IUseClinicModelWriteToClinicVisibilityProps): IUseClinicModelWriteToClinicVisibilityReturn => {
  const { isPatient, activeProfile } = useUserModelStore();
  const getChatPolicy = useChatModelChatPatientWritePolicy();
  const [isWriteToClinicButtonVisible, setIsWriteToClinicButtonVisible] = useState<boolean>(false);

  useEffect(() => {
    const getWriteToClinicVisibility = async (): Promise<void> => {
      const isWriteToClinicFeatureEnabled =
        defaultClinicPolicy === AllowDenyPolicyType.ALLOW &&
        patientWriteButtonVisibilityPolicy === AllowDenyPolicyType.ALLOW;

      if (!isWriteToClinicFeatureEnabled) return;

      if (!activeProfile) {
        // Show the button "write to" if incognito
        setIsWriteToClinicButtonVisible(true);

        return;
      }

      if (!isPatient) {
        setIsWriteToClinicButtonVisible(false);

        return;
      }

      if (clinicId && patientPortalUserId) {
        const { isPatientBlocked, activeChatOrRequest } = await getChatPolicy({
          clinicId,
          patientPortalUserId,
        });

        setIsWriteToClinicButtonVisible(isListing ? !isPatientBlocked : !isPatientBlocked && !activeChatOrRequest);
      }
    };

    getWriteToClinicVisibility();
  }, [defaultClinicPolicy, patientWriteButtonVisibilityPolicy, isPatient, clinicId, patientPortalUserId, isListing]);

  return { isWriteToClinicButtonVisible };
};
