import { ExpandLessIcon, ExpandMoreIcon, MaterialSymbolsIcon } from '@lib/icons';
import { Bubble, BubbleWithNumberIconWrapper, FlexBox, useModalState } from '@lib/react-components';
import { ClickAwayListener, Collapse, List, ListItem, ListItemText, Popover, Stack } from '@mui/material';
import { useEffect, useRef } from 'react';

import { useStyles as useMenuListStyles } from '../../styles';

import { IMenuListItemProps } from './MenuListItem';

import { usePortalConfiguration } from 'features/Portal';
import { useRootModelPath } from 'features/Root/model';
import { i18n } from 'i18n';
import { getSidebarItemConfig, SidebarItems, SidebarItemState } from 'lib/helpers/sidebarConfig';
import { ROUTE_PATHS } from 'routes/constants';

interface IConsultationMenuItemProps extends Omit<IMenuListItemProps, 'id'> {
  getIsSelected: (codeToCheck: SidebarItems) => boolean;
}

export const ConsultationMenuItem = ({
  code,
  isExpanded,
  state,
  getIsSelected,
  goToLink,
  getCounterForSidebarItem,
}: IConsultationMenuItemProps): JSX.Element => {
  const { getPath } = useRootModelPath();
  const { classes: menuListClasses } = useMenuListStyles();

  const isClinicsEnabled = usePortalConfiguration()?.isClinicsEnabled;

  const [isCollapsed, closeCollapsed, , toggleCollapsed] = useModalState(true);

  const { icon: Icon } = getSidebarItemConfig(code) ?? {};

  const anchorRef = useRef<HTMLLIElement | null>(null);

  const consultationItems = [
    {
      code: SidebarItems.OneToOneChats,
      link: ROUTE_PATHS.chatsOneToOneList,
      label: i18n.t('general.chatNominal'),
    },
    {
      code: SidebarItems.ClinicChats,
      link: ROUTE_PATHS.chatsClinicList,
      label: i18n.t('general.chatClinical'),
    },
  ];

  useEffect(() => {
    if (!isExpanded) {
      closeCollapsed();
    }
  }, [isExpanded]);

  const getBubble = (item: SidebarItems): JSX.Element | null => {
    const bubbleCounter = getCounterForSidebarItem(item);

    if (bubbleCounter && bubbleCounter > 0) {
      return <Bubble label={bubbleCounter} color="heavyRed" size="mobile" />;
    }

    return null;
  };

  if (isClinicsEnabled) {
    return isExpanded ? (
      <>
        <ListItem
          button
          disabled={state === SidebarItemState.DISABLED}
          className={menuListClasses.listItem}
          onClick={toggleCollapsed}
          data-cy={`sidebarItem-${code}`}
        >
          <FlexBox sx={{ flexDirection: 'column', width: '100%' }}>
            <FlexBox>
              {Icon && (
                <BubbleWithNumberIconWrapper numberValue={getCounterForSidebarItem(SidebarItems.Consultations)}>
                  <MaterialSymbolsIcon icon={<Icon />} fontSize={26} mr="10px" />
                </BubbleWithNumberIconWrapper>
              )}
              <ListItemText className={menuListClasses.listItemText} primary={i18n.t('general.menu.consultations')} />
              <MaterialSymbolsIcon
                ref={anchorRef}
                icon={!isCollapsed ? <ExpandLessIcon fontWeight="300" /> : <ExpandMoreIcon fontWeight="300" />}
                fontSize={24}
              />
            </FlexBox>
          </FlexBox>
        </ListItem>
        <Collapse in={!isCollapsed} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {consultationItems.map(({ link: treatmentLink, label: consultationLabel, code: consultationCode }) => (
              <ListItem
                key={treatmentLink}
                button
                selected={getIsSelected(consultationCode)}
                onClick={goToLink(getPath({ path: treatmentLink }))}
                sx={{ pl: 7 }}
              >
                <Stack direction="row" alignItems="center" gap={0.5}>
                  {consultationLabel}
                  {getBubble(consultationCode)}
                </Stack>
              </ListItem>
            ))}
          </List>
        </Collapse>
      </>
    ) : (
      <>
        <ListItem
          button
          disabled={state === SidebarItemState.DISABLED}
          className={menuListClasses.listItem}
          onClick={toggleCollapsed}
          data-cy={`sidebarItem-${code}`}
        >
          {Icon && (
            <BubbleWithNumberIconWrapper numberValue={getCounterForSidebarItem(SidebarItems.Consultations)}>
              <MaterialSymbolsIcon ref={anchorRef} icon={<Icon />} fontSize={26} mr="10px" />
            </BubbleWithNumberIconWrapper>
          )}
        </ListItem>
        <Popover
          open={!isCollapsed}
          anchorEl={anchorRef.current}
          anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
          hideBackdrop
        >
          <ClickAwayListener onClickAway={closeCollapsed}>
            <List component="div" disablePadding>
              {consultationItems.map(({ link: treatmentLink, label: consultationLabel, code: consultationCode }) => (
                <ListItem
                  key={treatmentLink}
                  button
                  selected={getIsSelected(consultationCode)}
                  onClick={(): void => {
                    closeCollapsed();
                    goToLink(getPath({ path: treatmentLink }))();
                  }}
                >
                  <Stack direction="row" alignItems="center" gap={0.5}>
                    {consultationLabel}
                    {getBubble(consultationCode)}
                  </Stack>
                </ListItem>
              ))}
            </List>
          </ClickAwayListener>
        </Popover>
      </>
    );
  }

  return (
    <ListItem
      className={menuListClasses.listItem}
      button
      selected={getIsSelected(code)}
      disabled={state === SidebarItemState.DISABLED}
      onClick={goToLink(getPath({ path: ROUTE_PATHS.chatsOneToOneList }))}
      data-cy={`sidebarItem-${code}`}
    >
      {Icon && (
        <BubbleWithNumberIconWrapper numberValue={getCounterForSidebarItem(SidebarItems.OneToOneChats)}>
          <MaterialSymbolsIcon icon={<Icon />} fontSize={26} mr="10px" />
        </BubbleWithNumberIconWrapper>
      )}
      {isExpanded && (
        <ListItemText className={menuListClasses.listItemText} primary={i18n.t('general.menu.consultations')} />
      )}
    </ListItem>
  );
};
