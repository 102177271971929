import { GlobalSearchAutocompleteOption } from '@lib/features-bll';
import qs from 'qs';
import { useEffect } from 'react';
import { matchPath, useLocation } from 'react-router-dom';

import { UseGlobalSearchModelStore } from './useGlobalSearchModelStore';

import { GlobalSearchHitType, GsdHsrClinicSubType } from '__generated__/types';
import {
  globalSearchDetailsModel,
  useGlobalSearchDetailsTabChangeHandler,
  useGlobalSearchDetailsTabsConfiguration,
  useGlobalSearchModelStore,
} from 'features/GlobalSearchDetails';
import { useRootModelPath } from 'features/Root/model';
import { ROUTE_PATHS } from 'routes/constants';

interface IUseGlobalSearchReturn {
  onSearch: (search: GlobalSearchAutocompleteOption) => void;
  globalSearch: ReturnType<UseGlobalSearchModelStore>['globalSearch'];
  setGlobalSearch: ReturnType<UseGlobalSearchModelStore>['setGlobalSearch'];
  allowEmptySearch: boolean;
}

export const useGlobalSearch = (): IUseGlobalSearchReturn => {
  const { getPath, goToPath } = useRootModelPath();
  const { globalSearch, setGlobalSearch } = useGlobalSearchModelStore();
  const { search } = globalSearchDetailsModel.useGlobalSearchFilters();
  const handleSearchTabChange = useGlobalSearchDetailsTabChangeHandler();
  const { priorityTab } = useGlobalSearchDetailsTabsConfiguration();
  const { pathname } = useLocation();

  const onSearch = ({ value, type, slug, clinicSubType, id }: GlobalSearchAutocompleteOption): void => {
    if (type === GlobalSearchHitType.DOCTOR) {
      goToPath({ path: ROUTE_PATHS.doctor, pathParams: { doctorSlug: slug ?? '' } });

      return;
    }

    if (type === GlobalSearchHitType.CLINIC) {
      goToPath({
        path: clinicSubType === GsdHsrClinicSubType.NON_INTEGRATED ? ROUTE_PATHS.freeClinic : ROUTE_PATHS.clinic,
        pathParams: { clinicId: id ?? '' },
      });

      return;
    }

    handleSearchTabChange(
      priorityTab,
      qs.stringify(
        {
          search: value || null,
          hitType: type || null,
        },
        { addQueryPrefix: true, skipNulls: true }
      )
    );
  };

  useEffect(() => {
    setGlobalSearch({ search, source: 'web' });
  }, [search]);

  useEffect(() => {
    if (globalSearch.source === 'app') {
      onSearch({ value: globalSearch.search });
    }
  }, [globalSearch.search]);

  return {
    globalSearch,
    setGlobalSearch,
    allowEmptySearch: !!matchPath(pathname, getPath({ path: ROUTE_PATHS.search })),
    onSearch,
  };
};
